/*
@each $font-face in 'font1', 'font2' {
  @font-face {
    font-family: $font-face;
    src:  url("fonts/" + $font-face + ".eot");
    src:  url("fonts/" + $font-face + ".eot?#iefix") format("embedded-opentype"),
          url("fonts/" + $font-face + ".woff") format("woff"),
          url("fonts/" + $font-face + ".ttf") format("truetype"),
          url("fonts/" + $font-face + ".svg#" + $font-face) format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
*/
html {
  overflow-x: hidden;
  position: relative;
  padding: 0;
  margin: 0;
  background: white;
}

body {
  position: relative;
  padding: 0 !important;
  margin: 0;
}

#load-images, #page-id, #vars {
  display: none;
}

#logo h1, .logo h1 {
  font-size: 0 !important;
}

h1, h2, h3, h4, h5, h6, ul, ol, p, a, img {
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
}

.wrapper {
  position: relative;
  margin: 0 auto 0;
  width: 95%;
}

.inline-block, .half, .one-third, .two-third, .one-fourth, .three-fourth, .one-fifth, .two-fifth, .three-fifth, .four-fifth, .one-sixth, .five-sixth, .one-seventh, .six-seventh, .one-eight, .seven-eight {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.half {
  width: 50%;
}

.one-third {
  width: 33.333333333333333%;
}

.two-third {
  width: 66.666666666666666%;
}

.one-fourth {
  width: 25%;
}

.three-fourth {
  width: 75%;
}

.one-fifth {
  width: 20%;
}

.two-fifth {
  width: 40%;
}

.three-fifth {
  width: 60%;
}

.four-fifth {
  width: 80%;
}

.one-sixth {
  width: 16.66666666666666%;
}

.five-sixth {
  width: 83.3333333333333%;
}

.one-seventh {
  width: 14.2857143%;
}

.six-seventh {
  width: 85.7142857%;
}

.one-eight {
  width: 12.5%;
}

.seven-eight {
  width: 87.5%;
}

.full {
  width: 100%;
  font-size: 0;
}

.clear-float {
  clear: both;
}

.centered {
  text-align: center;
}

.absolute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.delete-style {
  background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
}

.slided-up {
  max-height: 0;
}

.slided-down {
  max-height: 10000px;
}

@-moz-document url-prefix() {
  select {
    background-image: none !important;
    padding-right: 10px !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select {
    background-image: none !important;
    padding-right: 10px !important;
  }
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 40px;
  top: 0;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 501;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.95);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#F2FFFFFF, endColorstr=#F2FFFFFF);
  zoom: 1;
  background-image: url("/images/icons/loader.gif");
  background-size: 80px auto;
  background-position: center;
  background-repeat: no-repeat;
}

html {
  overflow: hidden;
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  min-width: 650px;
}

@-moz-document url-prefix() {
  select {
    background: #5fb3dc !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select {
    background: white !important;
  }
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: "Open sans", sans-serif;
  font-size: 18px;
  font-weight: 900;
  text-transform: none;
  overflow: hidden;
}

p {
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  font-weight: 900;
  text-transform: none;
  color: #005aa9;
  padding: 0;
  margin: 0;
}

h1 {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  padding: 0;
  margin: 0;
}

h2 {
  font-family: "Open sans", sans-serif;
  font-size: 17px;
  font-weight: 900;
  text-transform: none;
  color: #005aa9;
  padding: 0;
  margin: 0;
}

#map-bg, #start-inner {
  position: relative;
  height: 100%;
  background: url("/images/layout/bg.jpg") no-repeat center;
  background-size: 100%;
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src= $image, sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src=../images/layout/bg.jpg,sizingMethod='scale')";
}

#map-bg #map-bg-overlay, #map-bg #start-bg-overlay, #start-inner #map-bg-overlay, #start-inner #start-bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.75);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#BFFFFFFF, endColorstr=#BFFFFFFF);
  zoom: 1;
}

#map {
  background: none !important;
}

.infowindow {
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  font-weight: 900;
  text-transform: none;
  color: #005aa9;
  text-align: center;
  padding: 6px 0;
}

.gmnoprint, .gm-style-cc {
  display: none !important;
}

#fixed-head, .fixed-head, .fixed-foot {
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
}

#fixed-head #ticker, #fixed-head .ticker, .fixed-head #ticker, .fixed-head .ticker, .fixed-foot #ticker, .fixed-foot .ticker {
  width: 100%;
  background: white;
  position: relative;
  height: 42px;
}

@media (min-width: 1600px) {
  #fixed-head #ticker, #fixed-head .ticker, .fixed-head #ticker, .fixed-head .ticker, .fixed-foot #ticker, .fixed-foot .ticker {
    height: 50px;
  }
}

@media (max-width: 1025px) {
  #fixed-head #ticker, #fixed-head .ticker, .fixed-head #ticker, .fixed-head .ticker, .fixed-foot #ticker, .fixed-foot .ticker {
    height: 42px;
  }
}

#fixed-head #ticker .ticker-text, #fixed-head .ticker .ticker-text, .fixed-head #ticker .ticker-text, .fixed-head .ticker .ticker-text, .fixed-foot #ticker .ticker-text, .fixed-foot .ticker .ticker-text {
  margin: 12px 0;
  display: none;
  overflow-x: visible;
  border: 1px white solid;
  width: auto;
  position: absolute;
  right: -2000px;
  top: 0;
}

@media (max-width: 1025px) {
  #fixed-head #ticker .ticker-text, #fixed-head .ticker .ticker-text, .fixed-head #ticker .ticker-text, .fixed-head .ticker .ticker-text, .fixed-foot #ticker .ticker-text, .fixed-foot .ticker .ticker-text {
    margin: 12px 0;
  }
}

#fixed-head #ticker .ticker-text p, #fixed-head .ticker .ticker-text p, .fixed-head #ticker .ticker-text p, .fixed-head .ticker .ticker-text p, .fixed-foot #ticker .ticker-text p, .fixed-foot .ticker .ticker-text p {
  overflow-x: visible;
  width: auto;
  white-space: nowrap;
}

@media (min-width: 1600px) {
  #fixed-head #ticker .ticker-text p, #fixed-head .ticker .ticker-text p, .fixed-head #ticker .ticker-text p, .fixed-head .ticker .ticker-text p, .fixed-foot #ticker .ticker-text p, .fixed-foot .ticker .ticker-text p {
    font-size: 18px;
  }
}

@media (max-width: 1025px) {
  #fixed-head #ticker .ticker-text p, #fixed-head .ticker .ticker-text p, .fixed-head #ticker .ticker-text p, .fixed-head .ticker .ticker-text p, .fixed-foot #ticker .ticker-text p, .fixed-foot .ticker .ticker-text p {
    font-size: 13px;
  }
}

.fixed-foot {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

#fixed-head #ticker .selected-ticker, .fixed-head .ticker .selected-ticker, .fixed-foot .ticker .selected-ticker {
  display: inline-block;
}

#talen {
  text-align: right;
  z-index: 502;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

#talen .taal {
  display: inline-block;
  padding: 12px 15px;
  color: white;
  vertical-align: middle;
  font-family: "Open sans", sans-serif;
  font-size: 13px;
  font-weight: 900;
  text-transform: none;
}

@media (min-width: 1600px) {
  #talen .taal {
    padding: 20px 20px;
    font-size: 18px;
  }
}

@media (max-width: 1025px) {
  #talen .taal {
    padding: 12px 15px;
  }
}

#talen .taal#taal-n {
  background: #005aa9;
}

#talen .taal#taal-f {
  background: #009fe3;
}

#zoom-knoppen {
  position: fixed;
  top: auto;
  right: 20px;
  bottom: 30px;
  left: auto;
}

.zoom {
  cursor: pointer;
  display: block;
  padding: 10px 18px;
  color: white;
  margin: 1px;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 27px;
  font-weight: 900;
  text-transform: none;
}

@media (min-width: 1600px) {
  .zoom {
    padding: 15px 23px;
    font-size: 34px;
  }
}

.zoom#zoomout {
  background: #5fb3dc;
}

.zoom#zoomin {
  background: #009fe3;
}

#map {
  position: fixed !important;
  width: 100vw;
  height: 100%;
}

#logos {
  z-index: 500;
  position: relative;
  position: fixed;
  top: 80px;
  right: auto;
  bottom: 80px;
  left: 30px;
}

@media (min-width: 1600px) {
  #logos {
    left: 50px;
  }
}

@media (max-width: 1025px) {
  #logos {
    top: 50px;
  }
}

#logos .logo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 85px;
  margin-right: 35px;
}

@media (min-width: 1600px) {
  #logos .logo {
    height: 95px;
  }
}

@media (max-width: 1025px) {
  #logos .logo {
    height: 60px;
    margin-right: 20px;
  }
}

#logos .logo img {
  height: 100%;
}

.logo#keten-logo {
  display: none;
}

#footer-logo {
  z-index: 500;
  position: relative;
  position: fixed;
  top: auto;
  right: 30px;
  bottom: 20px;
  left: auto;
  width: 75px;
}

@media (min-width: 1600px) {
  #footer-logo {
    width: 85px;
    bottom: 35px;
    right: 50px;
  }
}

@media (max-width: 1025px) {
  #footer-logo {
    bottom: 15px;
    width: 55px;
  }
}

#footer-logo img {
  width: 100%;
}

#filters {
  z-index: 500;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 20px;
  left: 30px;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B3FFFFFF, endColorstr=#B3FFFFFF);
  zoom: 1;
  width: 340px;
  padding: 10px;
}

@media (min-width: 1600px) {
  #filters {
    bottom: 33vh;
    left: 50px;
    width: 370px;
  }
}

@media (max-width: 1025px) {
  #filters {
    bottom: 15px;
    width: 340px;
  }
}

.kies-soort {
  display: block;
  background-color: transparent;
  background-color: rgba(0, 159, 227, 0.65);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#A6009FE3, endColorstr=#A6009FE3);
  zoom: 1;
  margin-bottom: 10px;
  cursor: pointer;
}

@media (max-width: 1025px) {
  .kies-soort {
    margin-bottom: 6px;
  }
}

.kies-soort.kies-soort-selected {
  background: #005aa9;
}

.kies-soort:hover {
  background: #005aa9;
}

.kies-soort h1 {
  color: white !important;
  text-align: center;
  padding: 15px 0 !important;
}

@media (min-width: 1600px) {
  .kies-soort h1 {
    padding: 17px 0 !important;
    font-size: 17px;
  }
}

@media (max-width: 1025px) {
  .kies-soort h1 {
    padding: 12px 0 !important;
    font-size: 13px;
  }
}

.gotostart {
  display: block;
  background-color: transparent;
  background-color: rgba(0, 159, 227, 0.65);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#A6009FE3, endColorstr=#A6009FE3);
  zoom: 1;
  margin-bottom: 10px;
  cursor: pointer;
}

@media (max-width: 1025px) {
  .gotostart {
    margin-bottom: 6px;
  }
}

.gotostart:hover {
  background: #005aa9;
}

.gotostart h1 {
  color: white !important;
  text-align: center;
  padding: 15px 0 !important;
}

@media (min-width: 1600px) {
  .gotostart h1 {
    padding: 17px 0 !important;
    font-size: 17px;
  }
}

@media (max-width: 1025px) {
  .gotostart h1 {
    padding: 12px 0 !important;
    font-size: 13px;
  }
}

#filter-box {
  position: relative;
  background: white;
  margin-bottom: 10px;
}

@media (max-width: 1025px) {
  #filter-box {
    margin-bottom: 6px;
  }
}

#filter-box #filter-box-inner {
  display: block;
  padding: 20px 25px 15px;
}

@media (max-width: 1025px) {
  #filter-box #filter-box-inner {
    padding: 12px 11px 8px;
  }
}

#filter-box #filter-box-inner .one-third, #filter-box #filter-box-inner .two-third {
  vertical-align: top;
  margin-bottom: 5px;
}

#filter-box #filter-box-inner .one-third {
  width: 25%;
}

#filter-box #filter-box-inner .two-third {
  width: 75%;
}

#filter-box #filter-box-inner h1 {
  color: #005aa9;
  margin-bottom: 12px;
}

@media (min-width: 1600px) {
  #filter-box #filter-box-inner h1 {
    font-size: 17px;
  }
}

@media (max-width: 1025px) {
  #filter-box #filter-box-inner h1 {
    margin-bottom: 8px;
  }
}

#filter-box #filter-box-inner .check-optie {
  cursor: pointer;
  display: block;
  margin-bottom: 3px;
}

#filter-box #filter-box-inner .check-optie:hover .check {
  background: #009fe3;
}

#filter-box #filter-box-inner .check-optie img {
  display: none;
  width: 100%;
}

#filter-box #filter-box-inner .check-optie.check-optie-checked img {
  display: block;
}

#filter-box #filter-box-inner .check-optie .check {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  background: #005aa9;
}

#filter-box #filter-box-inner .check-optie#alleen-kwaliteit .check {
  background: #e5a304;
}

#filter-box #filter-box-inner .check-optie#alleen-producenten .check {
  background: #9bba0d;
}

#filter-box #filter-box-inner .check-optie#alleen-winkels .check {
  background: #4d4d4d;
}

#filter-box #filter-box-inner .check-optie p {
  display: inline-block;
  color: black;
  font-weight: 600;
  vertical-align: middle;
  font-size: 13px;
}

.kies-kaart {
  display: block;
  background-color: transparent;
  background-color: rgba(0, 159, 227, 0.65);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#A6009FE3, endColorstr=#A6009FE3);
  zoom: 1;
  cursor: pointer;
}

.kies-kaart:hover {
  background-color: transparent;
  background-color: #005aa9;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#FF005AA9, endColorstr=#FF005AA9);
  zoom: 1;
}

.kies-kaart h1 {
  color: white;
  text-align: center;
  padding: 15px 0;
}

@media (min-width: 1600px) {
  .kies-kaart h1 {
    padding: 17px 0;
    font-size: 17px;
  }
}

@media (max-width: 1025px) {
  .kies-kaart h1 {
    padding: 12px 0;
    font-size: 13px;
  }
}

#kaart-design {
  display: none;
}

input, select {
  color: white;
  -moz-border-radius: 0;
  -webkit-box-shadow: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 10px 12px;
  text-align: left;
  border-radius: 0;
  color: white;
  -webkit-border-radius: 0;
  font-family: "Open sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 8px;
  background: #5fb3dc right center url("/images/icons/select.png") no-repeat;
  cursor: pointer;
}

input:focus, input:hover, select:focus, select:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

input {
  cursor: auto;
  background: #5fb3dc;
}

select {
  padding-right: 44px;
}

#slide {
  overflow-y: scroll;
  display: none;
  z-index: 600;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.95);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#F2FFFFFF, endColorstr=#F2FFFFFF);
  zoom: 1;
  width: 600px;
}

@media (min-width: 1600px) {
  #slide {
    width: 700px;
  }
}

@media (max-width: 1025px) {
  #slide {
    width: 500px;
  }
}

#slide .slide-inner {
  display: none;
  padding: 55px 28px 40px;
}

@media (min-width: 1600px) {
  #slide .slide-inner {
    padding: 70px 42px;
  }
}

@media (max-width: 1025px) {
  #slide .slide-inner {
    padding: 55px 25px;
  }
}

#slide h1 {
  color: #5fb3dc;
  font-size: 28px;
  padding-bottom: 4px;
  border-bottom: 2px solid #5fb3dc;
  margin-bottom: 20px;
  text-transform: none;
}

@media (max-width: 1025px) {
  #slide h1 {
    font-size: 21px;
    margin-bottom: 15px;
  }
}

#slide h2 {
  margin-top: 15px;
  margin-bottom: 5px;
}

@media (max-width: 1025px) {
  #slide h2 {
    font-size: 15px;
    margin-bottom: 3px;
  }
}

#slide p {
  font-weight: 300;
  color: black;
  font-size: 16px;
}

@media (max-width: 1025px) {
  #slide p {
    font-size: 15px;
  }
}

#slide .winkel-lijst {
  display: inline-block;
  background-color: transparent;
  background-color: rgba(95, 179, 220, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B35FB3DC, endColorstr=#B35FB3DC);
  zoom: 1;
  color: white;
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  margin: 3px;
  padding: 4px 9px;
}

@media (max-width: 1025px) {
  #slide .winkel-lijst {
    font-size: 14px;
  }
}

#slide .producten {
  display: block;
}

#slide .producten table {
  width: 100%;
  margin-top: 15px;
  border: none;
}

#slide .producten table td {
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  color: black;
  padding: 5px 4px;
}

@media (min-width: 1600px) {
  #slide .producten table td {
    font-size: 14px;
  }
}

#slide .producten table th {
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  color: white;
  text-align: left;
  padding: 5px 7px;
}

#slide .producten table .head-row {
  background: #005aa9 !important;
}

#slide img {
  width: 100%;
  display: block;
  margin-top: 25px;
}

#slide img#foto-kwaliteit {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

#slide #close-slide {
  position: absolute;
  top: 35px;
  right: 15px;
  bottom: auto;
  left: auto;
  cursor: pointer;
}

@media (min-width: 1600px) {
  #slide #close-slide {
    top: 45px;
    right: 25px;
  }
}

@media (max-width: 1025px) {
  #slide #close-slide {
    top: 0;
    right: 10px;
  }
}

#slide #close-slide img {
  width: 30px;
}

.custom-combobox-input {
  background: #5fb3dc;
  margin-top: 25px;
  width: 50%;
}

#start {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 501;
  overflow-x: auto;
}

#start.start-hidden {
  display: none;
}

#start-inner {
  display: table;
  position: relative;
  width: 100%;
  text-align: center;
}

#start-inner-content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
}

#start-inner-content h1 {
  color: #005aa9;
  padding-bottom: 7px;
}

#start-inner-content input[type="text"] {
  width: 250px;
  margin: auto;
  background: white;
  color: #005aa9;
  text-align: center;
}

#start-talen {
  text-align: center;
  z-index: 9999999999999999999;
  display: block;
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  margin-left: 20px;
}

#start-talen .taal {
  display: inline-block;
  padding: 15px 25px;
  color: white;
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  font-weight: 900;
  text-transform: none;
}

@media (min-width: 1600px) {
  #start-talen .taal {
    padding: 16px 16px;
  }
}

@media (max-width: 1025px) {
  #start-talen .taal {
    padding: 15px 18px;
  }
}

#start-talen .taal#start-taal-n {
  background: #005aa9;
}

#start-talen .taal#start-taal-f {
  background: #009fe3;
}

.start-logo {
  position: relative;
  display: inline-block;
  margin: 0 45px 20px;
  vertical-align: middle;
}

@media (min-width: 1600px) {
  .start-logo {
    margin: 0 60px 70px;
  }
}

.start-logo img {
  width: 270px;
}

@media (min-width: 1600px) {
  .start-logo img {
    width: 350px;
  }
}

.start-logo.start-logo-small {
  margin-bottom: 0;
}

.start-logo.start-logo-small img {
  width: 120px;
}

@media (min-width: 1600px) {
  .start-logo.start-logo-small img {
    width: 180px;
  }
}

.start-logo.start-logo-medium img {
  width: 240px;
}

@media (min-width: 1600px) {
  .start-logo.start-logo-medium img {
    width: 300px;
  }
}

.start-keuze {
  background-color: transparent;
  background-color: rgba(0, 159, 227, 0.65);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#A6009FE3, endColorstr=#A6009FE3);
  zoom: 1;
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  margin: 0 4px;
}

.start-keuze h1 {
  color: white !important;
  text-align: center;
  padding: 10px 10px !important;
  font-size: 13px;
}

.start-keuze h1 span {
  display: inline-block;
  vertical-align: middle;
}

.start-keuze h1 span.material-icons {
  margin-right: 6px;
}

.start-keuze h1 span.material-icons:before {
  content: '\E836';
}

@media (min-width: 1600px) {
  .start-keuze h1 {
    padding: 12px 12px !important;
    font-size: 15px;
  }
}

.start-keuze.start-keuze-selected span.material-icons:before {
  content: '\E837';
}

.start-keuze:hover {
  background: #005aa9;
}

#start-submit {
  position: relative;
  display: inline-block;
  margin-top: 40px;
  min-width: 250px;
  padding: 15px 12px;
  font-family: "Open sans", sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  background: #005aa9;
  color: white;
  cursor: pointer;
}

@media (min-width: 1600px) {
  #start-submit {
    font-size: 25px;
    margin-top: 60px;
  }
}

#start-submit:hover {
  background: #00417c;
}

#help {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0;
  background: #d1d1d1;
  cursor: pointer;
}

#help:hover {
  background: #c0c0c0;
}

#help img {
  height: 42px;
}

@media (min-width: 1600px) {
  #help img {
    height: 64px;
  }
}

.toon-lijst {
  display: inline-block;
  vertical-align: middle;
  background: #5fb3dc;
  padding: 4px 7px;
  font-size: 15px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.toon-lijst:hover {
  background: #005aa9;
}

/*# sourceMappingURL=style.css.map */
